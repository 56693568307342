<template>
  <div>
    <div v-show="page == 1" class="add-info">
      <p class="title">プレビュー ＞ 説明会開催</p>

      <div class="content">

        <span class="right-btn green-btn" @click="changePage(3)">プレビューを閉じる</span>

        <div class="item">
          <span class="title">企業名</span>
          <span class="item-content">{{item.compName}}</span>
        </div>
        <div class="item">
          <span class="title">説明会タイトル</span>
          <span class="item-content">{{item.seminarTitle}}</span>
        </div>

        <div class="item">
          <span class="title">対象年度</span>
          <span class="item-content">{{item.forTheYearText}}</span>
        </div>

        <div class="item">
          <span class="title">開催日時</span>
          <span class="item-content">{{item.startDate}} , {{item.endDate}}</span>
        </div>
        <div class="item">
          <span class="title">説明会詳細</span>
          <span class="item-content">
            {{item.seminarDetail}}
          </span>
        </div>
        <div class="item">
          <span class="title">開催場所</span>
          <span class="item-content">{{item.venue}}</span>
        </div>

        <div class="item-buttons">
        <div  @click="changePage(3)" class="next-button green-btn">プレビューを閉じる</div>
        <div @click="changePage(2)" class="next-button cf-btn">次へ ▶︎</div>
        </div>

      </div>
    </div>

    <div v-show="page == 2" class="add-info">
      <p class="title">プレビュー ＞ 説明会開催</p>

      <div class="content">

        <span class="right-btn green-btn" @click="changePage(3)">プレビューを閉じる</span>

        <div class="item">
          <span class="title">受付タイトル</span>
          <span class="item-content">{{item.appTitle}}</span>
        </div>
        <div class="item">
          <span class="title">受付内容</span>
          <span class="item-content">
            {{item.appDetails}}
          </span>
        </div>
        <div class="item">
          <span class="title">受付電話番号</span>
          <span class="item-content">{{item.appContactInformation}}</span>
        </div>
        <div class="item">
          <span class="title">受付メールアドレス</span>
          <span class="item-content">{{item.appContactEmail}}</span>
        </div>
        <div class="item-buttons">
          <div  @click="changePage(1)" class="next-button green-btn">◀︎ 戻る</div>
          <div @click="changePage(3)" class="next-button green-btn">プレビューを閉じる</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "explainMeetingDetail",

  data() {
    return {
      page: 1,
      item:{

      }
    }
  },

  methods: {
    initData(data){
      console.log(data);
      this.item=data;
    },
    changePage(type) {
      if(type == 1 || type == 2) {
        //下一页
        this.page = type;
      } else if (type == 3) {
        //返回
        this.$emit('changeType', 1);
        this.page = 1;
        // this.$emit('changeType', 1);
      }
    }
  }


}
</script>

<style scoped>
.add-info {
  margin-top: 3.125rem;
}

.add-info>.title {
  
  font-style: normal;
  font-weight: 600;
  font-size: .9375rem;
  line-height: 1.1rem;

  color: #424242;

  margin: 3.125rem 0 1.4rem 0;
}

.right-btn{
  border:1px solid #424242;position: absolute;right:1.6rem;top:1.8rem;
  border-radius: 10rem;z-index:10;cursor: pointer;font-size:0.9375rem;
  width:10.8125rem;text-align: center;display: inline-block;height:1.875rem;line-height:1.875rem;

}

.content {
  flex: 1;
  /*height: 100vh;*/
  background: #FFFFFF;
  box-shadow: .234375rem .234375rem 0px rgba(0, 0, 0, 0.05);
  border-radius: .625rem;

  box-sizing: border-box;
  position: relative;
  padding: .391rem 1.172rem 1.953rem;
}

.content .item {
  display: flex;
  margin-top: 2.734rem;
  margin-right: 6.094rem;
}

.content .item .title {
  width: 10.122rem;
  
  font-style: normal;
  font-weight: 600;
  font-size: 1.09375rem;
  line-height: 1.9rem;
  text-align: right;
  margin-right: 2.456rem;
  color: #CCCCCC;
  /*padding-bottom: 2.709rem;*/
}

.content .item .item-content {
  flex: 1;
  
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.9rem;
  /* identical to box height, or 150% */

  padding-bottom: 2.709rem;
  border-bottom: 0.0585938rem solid #CCCCCC;
  color: #000000;

  white-space: pre-wrap;
}

.content .next-button {
  width: 15.375rem;
  height: 2.5rem;
  margin: 5.444rem auto 0;
  background: #FFFFFF;
  border: 0.078125rem solid #424242;
  border-radius: 1.95312rem;
  
  font-style: normal;
  font-weight: 700;
  font-size: 1.09375rem;
  line-height: 2.5rem;
  text-align: center;

  color: #424242;
  cursor: pointer;
}

.item-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-buttons div {
  margin: 5.444rem 0 0!important;
  /*margin-top: 5.444rem;*/
}

.item-buttons div:first-child {
  /*margin: 5.444rem 5.16rem;*/
  margin-right: 5.16rem !important;
}
</style>
